<template>
  <div v-padding:top="46">
    <van-nav-bar title="站点信息申报" fixed left-arrow @click-left="goBack">
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group>
        <van-field
          readonly
          clickable
          name="picker"
          v-model="formData.name"
          label="所属站点:"
          placeholder="请选择所属站点"
          @click="onShowAction"
          :rules="[{ required: true, message: '请选择所属站点' }]"
        />
      </van-cell-group>
      <van-cell-group>
        <upload-file
          :uuid="fileUUID"
          ref="uploadFile"
          :type="$route.query.operate"
        ></upload-file>
      </van-cell-group>
      <van-cell-group title="手动签名">
        <van-cell title=" ">
          <template slot="default">
            <div class="sign-img">
              <img
                :src="signData.imgUrl"
                v-if="signData.imgUrl"
                alt=""
                @click="onReSign()"
              />
            </div>
          </template>
          <template slot="right-icon">
            <van-button
              type="primary"
              size="mini"
              @click="onSign"
              v-if="!signData.imgUrl"
              native-type="button"
              >签名</van-button
            >
          </template>
        </van-cell>
      </van-cell-group>

      <sign-modal v-model="signModalObj" @confirm="onSignConfirm"></sign-modal>
      <van-cell-group
        title="审核信息"
        class="edit-div"
        v-if="$route.query.operate == 'edit' && formData.status != '1'"
      >
        <van-cell value=" ">
          <template #title>
            <span class="custom-title">审核结果</span>
            <van-tag
              :type="formData.value == '通过' ? 'success' : 'danger'"
              style="margin-left: 42px"
              size="medium"
              >{{ formData.value }}</van-tag
            >
          </template>
        </van-cell>

        <van-cell title="备注" :value="formData.remark"> </van-cell>
      </van-cell-group>
      <van-row>
        <van-col span="24" v-if="this.$route.query.operate == 'add'">
          <van-button
            block
            type="primary"
            native-type="submit"
            :loading="submitLoading"
          >
            保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
    <van-action-sheet
      title="请选择站点名称"
      :close-on-click-action="true"
      v-model="isShowAction"
      :actions="actions"
      @select="onActionSelect"
      :style="{ height: '62%' }"
    />
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import uploadFile from "@/components/common/upload-file.vue"; // 公共文件上传
import signModal from "@/components/common/sign.vue"; // 公共签名

export default {
  components: { signModal, uploadFile },
  props: {},
  data() {
    return {
      isShowAction: false,
      enterpriseId: localStorage.getItem("enterpriseId"),
      formData: {
        relatedId: "",
        name: "",
        status: "",
        value: "",
        remark: "",
      },
      file: [],
      uploadedFile: [],
      fileType: [],
      submitLoading: false,
      calendarShow: false, // 日历
      calendarType: "", // 时间类型
      defaultDate: this.$moment().toDate(),
      signatureUuid: "", // 签名uuid
      signData: {
        imgUrl: "",
        file: "",
        uuid: "",
      },
      fileUUID: "", // 附件uuid
      signModalObj: {},
      actions: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getAllPointList();
    if (this.$route.query.operate == "edit") {
      this.getsMonitorinspectedInfo();
    } else {
      this.getFileUUID();
    }
  },
  methods: {
    onShowAction() {
      if (this.$route.query.operate == "edit") {
        this.isShowAction = false;
      } else {
        this.isShowAction = true;
      }
    },
    // 显示弹出层
    showAction() {
      this.isShowAction = true;
    },
    // 动作面板选择
    onActionSelect(action) {
      this.formData.name = action.name;
      this.formData.relatedId = action.value;
    },
    // 查询所有站点
    getAllPointList() {
      const data = {
        enterpriseName: "",
        poiName: "",
        poiCode: "",
        mn: "",
      };
      this.$api.enterprise.getAllPointList(data).then((res) => {
        if (res.data.state == 0) {
          this.pointList = res.data.data || [];
          const array = [];
          for (let i = 0; i < this.pointList.length; i++) {
            const item = {};
            item["name"] = this.pointList[i].poiName;
            item["subname"] = this.pointList[i].enterpriseName;
            item["value"] = this.pointList[i].id;
            // isNotReview为1代表该站点下有审核中申报 禁用选项
            item["disabled"] = this.pointList[i].isNotReview === 1;
            array.push(item);
          }
          this.actions = array;
          console.log("this.actions", this.actions);
        }
      });
    },
    // 新增站点信息申报
    async addMonitorinspectedInfo() {
      this.submitLoading = true;
      if (!this.signData.file) {
        this.submitLoading = false;
        Toast.fail("请进行签名");
        return false;
      }
      await this.$refs.uploadFile.onSubmit().catch((err) => {
        this.submitLoading = false;
        Toast.fail(`上传附件失败：${err.msg}`);
        throw err;
      });

      await this.getUUID().catch((err) => {
        this.submitLoading = false;
        Toast.fail("获取uuid失败，请重试");
        throw err;
      });

      await this.uploadFile().catch((err) => {
        this.submitLoading = false;
        Toast.fail(`上传签名失败:${err.msg}`);
        throw err;
      });
      const data = {
        relatedId: this.formData.relatedId,
        fileUuid: this.fileUUID, // 附件id
        signatureUuid: this.signData.uuid,
        type: 3,
        relatedName: this.formData.name,
        // id: this.$route.query.id
        // 签名id
      };
      this.$api.setting
        .postAddInformationReview(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.success("新增成功");
            this.goBack();
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    // 重新签名
    onReSign() {
      if (this.$route.query.operate == "add") {
        Dialog.confirm({
          title: "重新签名",
          message: "是否重新签名",
        })
          .then(() => {
            this.onSign();
          })
          .catch(() => {});
      }
    },
    // 签名
    onSign() {
      this.signModalObj = {
        title: "签名",
        isShow: true,
      };
    },

    // 完成签名
    onSignConfirm(signData) {
      this.signData = {
        imgUrl: signData.imgDataBase,
        file: signData.file,
      };
    },
    // 获取uuid
    getUUID() {
      return new Promise((resolve, reject) => {
        if (!this.signData.file) {
          resolve();
        } else {
          this.$api.common
            .getUuid()
            .then((res) => {
              if (res.data.state == 0) {
                this.signData.uuid = res.data.data;
                resolve();
              } else {
                reject();
              }
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
    // 获取附件uuid
    getFileUUID() {
      this.$api.common.getUuid().then((res) => {
        if (res.data.state == 0) {
          this.fileUUID = res.data.data;
        }
      });
    },
    onSubmit() {
      this.addMonitorinspectedInfo();
    },
    // 附件上传
    uploadFile() {
      return new Promise((resolve, reject) => {
        if (!this.signData.file) {
          resolve();
        } else {
          const formData = new FormData();
          formData.append("file", this.signData.file);
          formData.append("uuid", this.signData.uuid);
          this.$api.common
            .postUploadUuid(formData)
            .then((res) => {
              if (res.data.state == 0) {
                resolve();
              } else {
                reject();
              }
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },

    // 获取站点信息申报详情
    getsMonitorinspectedInfo() {
      const data = {
        id: this.$route.query.id,
      };
      this.$api.setting.getInformationReviewById(data).then((res) => {
        if (res.data.state == 0) {
          this.formData = {
            name: res.data.data.relatedName,
            relatedId: res.data.data.relatedId,
            remark: res.data.data.remark,
            status: res.data.data.status,
            value: res.data.data.status == "2" ? "通过" : "不通过",
          };
          this.fileUUID = res.data.data.fileUuid;
          this.getSignFile(res.data.data.signatureUuid);
        }
      });
    },
    // 获取签名图片
    getSignFile(signatureUuid) {
      const data = {
        uuid: signatureUuid,
      };
      this.$api.common.getFileByUuid(data).then((res) => {
        if (res.data.state == 0) {
          this.signData.imgUrl =
            this.$api.base.file + "/" + res.data.data[0].ossFileName;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.sign-img {
  width: 150px;
  height: 75px;
  img {
    width: 100%;
    height: 100%;
  }
}
.edit-div {
  .van-cell__title {
    max-width: 43%;
    text-align: left;
  }
  .van-cell__value {
    min-width: 57%;
    margin-left: -48px;
    text-align: left;
    span {
      display: inline-block;
      text-align: left;
      word-break: break-all;
    }
  }
}
</style>
